import React from "react";
import { TABLE_HEADERS, id } from "../../common/statics";
import { useDispatch, useSelector } from 'react-redux';
import WarningAlert from "../../common/warning_alert";
import { high_sum } from "../../texts/fill";
import { getRow } from "../../utils/draw_file_utils";
import FileFunctions from "./file_functions";

const DrawFile = () => {
    const file = useSelector(state => state.file);
    const user = useSelector(state => state.user);
    const sumAllError = useSelector(state => state.sumAllError);
    const dispatch = useDispatch();

    return (
        <div >
            <FileFunctions />
            <hr className="my-4" />
            {sumAllError !== null && <WarningAlert msg={high_sum} />}
            <table className="table table-hover" style={{ fontSize: '13px' }}>
                <thead style={{position: 'sticky', top: '102px'}}>
                    <tr>
                        {TABLE_HEADERS.map(item => (
                            <th scope="col">{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {file.map((row) =>
                       <tr key={row[id]}> {getRow(row, user, dispatch)} </tr>
                    )}
                </tbody>
            </table>
            <br />
            <hr className="my-4" />
        </div>
    )
};

export default DrawFile;