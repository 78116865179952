import React, { useState } from "react";
import DownloadExample from "./download_example";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { empty_file } from "../../texts/fill";
import { read, utils } from "xlsx";
import { isRowsExists } from "../../utils/validation_utils";
import { saveFile } from "../../context/fileReducer";
import { saveError } from "../../context/errorReducer";

const FileHandler = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState(false)
    const acceptFileTypes = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

    const handleImport = ($event) => {
        dispatch(saveError(null))
        let newValue;
        if ($event) {
            const files = $event.target.files;
            if (files.length) {
                const file = files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                    const wb = read(event.target.result);
                    const sheets = wb.SheetNames;
                    if (sheets.length) {
                        newValue = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        if (isRowsExists(newValue)) {
                            dispatch(saveFile(newValue));
                            navigate("/uploadedFile")
                        } else setError(true)
                    }
                }
                reader.readAsArrayBuffer(file);
            }
        }
    }

    return (
        <div className="input-group border border-dark p-3  bg-secondary d-inline" >
            <DownloadExample />
            <br />
            <input type="file" className="m-lg-4" onChange={e => handleImport((e))}
                accept={acceptFileTypes} />
            <br />
            <br />
            {error && <div className="alert alert-danger" role="alert">
                {empty_file}
            </div>}
        </div>
    )
};

export default FileHandler;
