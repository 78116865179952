import React from "react";
import Function from "./function";
import { reupload } from "../../texts/fill";
import { useNavigate } from "react-router-dom";
import SendFileButton from "./send_file_button";

const FileFunctions = () => {
  const navigate = useNavigate();

  const reUpload = () => {
    navigate("/fill")
  }

  return (
    <nav className="navbar navbar-light bg-light justify-content-between sticky-top px-3">
      <Function />
      <SendFileButton />
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={reUpload}
      >
        {reupload}
        <span> &raquo;</span>
      </button>
    </nav>
  );
};

export default FileFunctions;