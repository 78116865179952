import {
    last_name,
    first_name,
    street,
    num,
    ishuv,
    phone,
    mobilePhone,
    id,
    colel,
    children_num,
    sum, bankNum,
    bankBranchNum,
    bankAccount,
    food_chain,
    comments
} from "../common/statics"
import { getCodeIshuv } from "../common/codeIshuvList"
import { getcodeMosad } from "../common/codeMosadList"
import { getFoodChainCode } from "../common/codeFoodChainList"
import { updateProperty } from "../context/fileReducer";


export const buildRequest = (file) => {
    const body = []
    file.forEach((row) => {
        body.push(convertObject(row))
    })
    return body;
}

const convertObject = (row) => {
    const codeIshuv = getCodeIshuv(row[ishuv])
    const codeMosad = getcodeMosad(row[colel])
    const foodChainCode = getFoodChainCode(row[food_chain])
    return {
        tz: Number(row[id]),
        lastName: row[last_name],
        firstName: row[first_name],
        street: row[street],
        streetNum: Number(row[num]),
        homeNum: Number(row[num]),
        codeIshuv: Number(codeIshuv),
        newIshuv: codeIshuv === null ? row[ishuv] : null,
        phoneNum: row[phone]?.toString(),
        mobilePhoneNum: row[mobilePhone] ? String(row[mobilePhone]): null,
        codeMosad: codeMosad,
        newMosad: codeMosad === null ? row[colel] : null,
        childrenNum: row[children_num],
        foodChainCode: foodChainCode,
        bankNum: row[bankNum],
        bankBranchNum: row[bankBranchNum],
        bankAccount: row[bankAccount],
        milgaSum: parseInt(row[sum])
    };
}

export const handleErrors = (data, file, dispatch) => {
    file.forEach((row, index) => {
        if (data[row[id]] != null) {
            dispatch(updateProperty(index, comments, data[row[id]]));
        }
    })

}
