import { food_chain, REQUIRED_FIELDS, sum, mobilePhone, phone, INT_FIELDS, id } from "../common/statics";

export const validateRow = (row, user) => {
    const error = [];
    const sumForEachOneResult = isSumForEachOneMatching(row, user);
    if (sumForEachOneResult !== true)
        error.push(sumForEachOneResult);

    const requiredFieldsResult = areRequiredFieldsExist(row, user);
    if (requiredFieldsResult !== true)
        error.push(requiredFieldsResult);

    const columnTypeMatching = isColumnTypeMatching(row);
        if (columnTypeMatching !== true)
            error.push(columnTypeMatching);
    
    if (user.foodChain !== null) {
        const foodChainResult = foodChainExist(row, user);
        if (foodChainResult !== true)
            error.push(foodChainResult);
    }

    isIdValid(row);

    return error
}

export const isSumAllMatching = (rows, user, newValue) => {
    const calcSum = rows.length * newValue;
    return calcSum < (user.approvalAmount - user.enteredAmount);
}

export const isColumnTypeMatching = (row) => {
    for (const property of INT_FIELDS) {
        if(row[property] !== "" && !(/^\d+$/.test(row[property]))){
            return "שדה מספרי מכיל אותיות"
        }
    }
    return true;
}

export const isRowsExists = (rows) => {
    return rows.length > 0;
}

const isSumForEachOneMatching = (row, user) => {
    if (user.amountLimitForEach !== null && row[sum] > user.amountLimitForEach) {
        return "סכום גבוה מהסכום שאושר"
    }
    return true
}

const areRequiredFieldsExist = (row, user) => {
    const requiredFields = REQUIRED_FIELDS(user);
    for (const property of requiredFields) {
        if (property === mobilePhone) {
            if (row[mobilePhone] === "" && row[phone] === "") {
                return "שדות חובה חסרים";
            }
        }
        else if (row[property] === "") {
            return "שדות חובה חסרים";
        }
    }
    return true
}

const foodChainExist = (row, user) => {
    if (user.foodChain === null && row[food_chain] === "") {
        return "שדה רשת מזון חסר"
    }
    return true;
}

const isIdValid = (row) => {
	id = String(id).trim();
	if (id.length > 9 || isNaN(id)) return false;
	id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
		return Array.from(id, Number).reduce((counter, digit, i) => {
			const step = digit * ((i % 2) + 1);
			return counter + (step > 9 ? step - 9 : step);
		}) % 10 === 0;
}

