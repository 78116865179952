const initialState = null;

export const SAVE_FILE = 'SAVE_FILE';

export const saveFile = (file) => {
    return {
        type: SAVE_FILE,
        payload: file,
    };
};

export const UPDATE_PROPERTY_FOR_ALL = 'UPDATE_PROPERTY_FOR_ALL';

export const updatePropertyForAll = (propertyName, newValue) => {
    return {
        type: UPDATE_PROPERTY_FOR_ALL,
        payload: { propertyName, newValue },
    };
};
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';

export const updateProperty = (index, propertyName, newValue) => {
    return {
        type: UPDATE_PROPERTY,
        payload: { index, propertyName, newValue },
    };
};

export const fileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_FILE:
            return action.payload;
        case UPDATE_PROPERTY:
            const { index, propertyName, newValue } = action.payload;
            const updatedData = [...state];
            updatedData[index][propertyName] = newValue;
            return updatedData;
        case UPDATE_PROPERTY_FOR_ALL:
            const { propertyName: propName, newValue: propValue } = action.payload;
            return state.map((item) => ({
                ...item,
                [propName]: propValue,
            }));
        default:
            return state;
    }
};
