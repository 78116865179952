import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { fileReducer } from './fileReducer';
import { errorReducer } from "./errorReducer";
import { sumAllErrorReducer } from "./sumAllErrorReducer";

const rootReducer = combineReducers({
    user: userReducer,
    file: fileReducer,
    error: errorReducer,
    sumAllError: sumAllErrorReducer,
});

export default rootReducer;
