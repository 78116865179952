const LIST_OF_MOSAD = {
  1: 'מורשה לדעת',
  2: 'ברומברג',
  3: 'זיו התורה',
  4: 'הילמן',
  5: 'נחלת הלויים',
  6: 'אוהבי תורתיך',
  7: 'חכמה ודעת',
  8: 'זכרון שלום',
  9: 'חמד (ק)',
  10: 'שערי ציון',
  11: 'נר ישראל',
  12: 'בונינו',
  13: 'מיר',
  14: 'המרכזי-רכס',
  15: 'אהל מנחם',
  16: 'למען החי',
  17: 'נבנצל',
  18: 'פונוביז',
  19: 'הדף היומי',
  20: 'אלכסנדר',
  21: 'הר"ן',
  22: 'לוינשטיין',
  23: 'ת"ת',
  24: 'בואיאן',
  25: 'עטרת אליהו',
  26: 'כנסת בית אברהם',
  27: 'לייזעש',
  28: 'קרית מלך',
  29: 'שערי שמעון',
  30: 'פונוביז לצעירים',
  31: 'פונוביז',
  32: 'תרביצא ירושלים',
  33: 'פחד יצחק',
  34: 'נתיבות המשפט',
  35: 'נחלת דוד',
  36: 'זכרון משה',
  37: 'הר צבי',
  38: 'נחלת גדעון',
  39: 'בריסק',
  40: 'דרכי תורה',
  41: 'מיר (ברכפלד)',
  42: 'תא שמע',
  43: 'עליות בית חנניה',
  44: 'נחלת מיכאל',
  45: 'חיי תורה',
  46: 'ארחות תורה',
  47: 'נחלת משה',
  48: 'ישיבה גדולה דמכסיקו',
  49: 'מעשה חייא',
  50: 'קמניץ לצעירים',
  51: 'אור גאון',
  52: 'לוצערן',
  53: 'קול תורה לצעירים',
  54: 'ברכת ישראל',
  55: 'ברכת אפרים',
  56: 'אופקים',
  57: 'רכסים',
  58: 'עמק הלכה',
  59: 'תפארת משה',
  60: 'עטרת ישראל',
  61: 'גרודנא (באר יעקב)',
  62: 'תפרח',
  63: 'אור שמואל',
  64: 'דרכי משה',
  65: 'בעלזא',
  66: 'עץ חיים',
  67: 'טשעבין',
  68: 'חזון איש',
  69: 'כנסת שלום',
  70: 'אור ישראל לצעירים',
  71: 'בית מדרש עליון',
  72: 'גרודנא (אשדוד)',
  73: 'משכן ישראל',
  74: 'כנסת יצחק (ק"ס)',
  75: 'ארחות חיים',
  76: 'בית מאיר לצעירים',
  77: 'ברית יעקב',
  78: 'אור ברוך',
  79: 'יד אהרן',
  80: 'מעלות חיים',
  81: 'קהילות יעקב',
  82: 'ארחות תורה',
  83: 'גור',
  84: 'עטרת שלמה',
  85: 'עמודי עולם',
  86: 'הר"ן (ברכפלד)',
  87: 'בית הלל',
  88: 'אהבת שלום',
  89: 'בריסק',
  90: 'הר יונה',
  91: 'ישיבה לצעירים רחובות',
  92: 'אביר יעקב (נהריה)',
  93: 'טשארנוביל',
  94: 'יטב לב סטמר',
  95: 'אהל המשפט',
  96: 'בית שלום',
  97: 'קצות החושן',
  98: 'ברכת התורה',
  99: 'חכמת שלמה',
  100: 'שערי בינה',
  101: 'תפארת הלוי',
  102: 'תפארת יעקב (גייטסהעד)',
  103: 'משכנות התורה',
  104: 'סלבודקא',
  105: 'קול תורה',
  106: 'מאור התלמוד',
  107: 'אהל משה',
  108: 'קרית מלך',
  109: 'באר התורה',
  110: 'תפארת יעקב',
  111: 'אור ישראל',
  112: 'המרכזי שיכון ג',
  113: 'מיר (ברכפלד)',
  114: 'בית מדרש עליון',
  115: 'בית מאיר',
  116: 'כנסת יצחק (חדרה)',
  117: 'גבעת רוקח',
  118: 'אור אלחנן',
  119: 'משכן שלמה',
  120: 'רבנו חיים עוזר',
  121: 'בית מדרש להוראה (גור)',
  122: 'מאור יצחק (חמד)',
  123: 'בית מאיר',
  124: 'גייסטעהד',
  125: 'כנסת חזקיהו לצעירים',
  126: 'בית מתתיהו',
  127: 'פונוביז לצעירים',
  128: 'אור אלחנן לצעירים (ברכפלד)',
  129: 'אמרי צבי',
  130: 'גרודנא',
  131: 'תפארת ציון',
  132: 'באיאן',
  133: 'כנסת יצחק (ק"ס)',
  134: 'תורת זאב',
  135: 'ישרי לב',
  136: 'חכמה ודעת',
  137: 'בעלזא מכנובקא',
  138: 'תפארת מ"ב',
  139: 'כנסת יעקב',
  140: 'פורת יוסף (גאולה)',
  141: 'תפארת התלמוד',
  142: 'שערי תורה',
  143: 'נחלת דוד (ק)',
  144: 'סלאנים',
  145: 'קרלין סטולין',
  146: 'טללי אורות',
  147: 'זכרון מאיר',
  148: 'בגדי הקודש',
  149: 'עץ חיים',
  150: 'משכנות הרועים',
  151: 'רבינו חיים עוזר',
  152: 'נזר התורה',
  153: 'דרך חכמה',
  154: 'בית מדרש גבוה - ליקווד',
  155: 'סלבודקא',
  156: 'מרגניתא דאברהם',
  157: 'כנסת ישראל',
  158: 'אהל יצחק שרגא',
  159: 'ארחות משה',
  160: 'יד אליעזר',
  161: 'ברכת משה (פרשבורג)',
  162: 'אהל אברהם',
  163: 'רינת התורה',
  164: 'תפארת איתמר',
  165: 'רבנו יוסף חיים',
  166: 'שערי תורה (ביתר)',
  167: 'חברון',
  168: 'אורחות תורה',
  169: 'עטרת שלמה - חריש',
  170: 'אברכים',
  171: 'אהלי דבורה',
  172: 'אוהל יוסף',
  173: 'אור אלחנן לצעירים',
  174: 'עטרת מרדכי-רחובות',
  175: 'תורת פנחס',
  176: 'היכל רפאל',
  177: 'אור ישראל לצעירים',
  178: 'אילת השחר',
  179: 'איתרי',
  180: 'איתרי',
  181: 'אמרי נועם',
  182: 'אקסלהבן',
  183: 'בני תורה-רכסים',
  184: 'באבוב',
  185: 'באר התורה (ק)',
  186: 'באר התלמוד',
  187: 'בית אבא',
  188: 'בית אברם סלונים',
  189: 'בית אהרן וישראל',
  190: 'בית אהרן וישראל',
  191: 'בית אורות',
  192: 'בית דוד',
  193: 'בית התלמוד',
  194: 'תורה למשה',
  195: 'בית יעקב',
  196: 'בית ישראל',
  197: 'שערים בהלכה',
  198: 'בית פסח ורחל',
  199: 'אוצר הפוסקים',
  200: 'בית שמעיה',
  201: 'אור תורת יוסף',
  202: 'ברכת התורה',
  203: 'ברכת ישראל',
  204: 'ברכת משה',
  205: 'אורה של תורת חסד',
  206: 'גאון יעקב',
  207: 'גבעת שאול',
  208: 'גור',
  209: 'גייסטעהד',
  210: 'גרודנא',
  211: 'בי"ש',
  212: 'שערי שמחה בי"ש',
  213: 'דעת חכמה',
  214: 'ברית אברהם',
  215: 'דרכי איש',
  216: 'דרכי חיים',
  217: 'דרכי מרדכי',
  218: 'הכרם',
  219: 'הנגב',
  220: 'הנגב',
  221: 'הרמה',
  222: 'השקדנים ביתר',
  223: 'זכרון אליהו',
  224: 'הרב פרוינד',
  225: 'גיבורי חיל',
  226: 'וולפסון',
  227: 'זרע יצחק',
  228: 'חברון גאולה',
  229: 'חדות התלמוד',
  230: 'חזון יחזקאל',
  231: 'חזון נחום',
  232: 'חידושי הרים',
  233: 'חיי עולם',
  234: 'חכמת שלמה',
  235: 'חניכי הישיבות',
  236: 'חסד לאברהם ומרדכי',
  237: 'חסדי שיר',
  238: 'טל אורות',
  239: 'פוניבז',
  240: 'יד אהרן',
  241: 'יוצאי חברון',
  242: 'יוצאי פונוביז',
  243: 'מנחת-אשר',
  244: 'ויאל משה',
  245: 'ירוחם',
  246: 'ישועה לדוד',
  247: 'כוכב יעקב טשעבין',
  248: 'כוכב מיעקב טשעבין',
  249: 'כנסת חזקיהו',
  250: 'כנסת יהודא',
  251: 'כנסת יחזקאל',
  252: 'הוראה ירושלים',
  253: 'כסא רחמים',
  254: 'לייקווד',
  255: 'ירושלים-עטרת',
  256: 'מהרש"ם',
  257: 'מעלות התורה',
  259: 'נזר התורה',
  262: 'נר משה',
  264: 'ספינקא',
  265: 'עזרת תורה',
  266: 'עמודי אש',
  269: 'כסא רחמים',
  270: 'קול יעקב',
  271: 'קרן אורה',
  272: 'רשב"י',
  273: 'שפתי דעת',
  274: 'תהילות יואל סאטמר',
  275: 'תורה אור',
  276: 'תורת אמת',
  277: 'תורת הנחל',
  278: 'תפארת מרדכי',
  279: 'כף החיים',
  280: 'לייקווד',
  281: 'מאה שערים',
  282: 'מאור יצחק',
  284: 'מעיין התלמוד',
  286: 'משך חכמה',
  287: 'מתיבתא',
  288: 'נאוואמינק',
  289: 'נועם התלמוד',
  290: 'נחלת דוד',
  293: 'נפש החיים',
  294: 'נר משה',
  295: 'נתיבות התלמוד',
  296: 'נתיבות ישראל',
  297: 'סאטמאר',
  298: 'סיקוורא',
  299: 'סלבודקא לצעירים',
  303: 'פינסק קרלין',
  304: 'פרשבורג',
  305: 'צאנז',
  308: 'קדוש יעקב',
  310: 'קול יעקב',
  311: 'קמניץ',
  312: 'קרית מלאכי',
  313: 'קרלין לצעירים',
  314: 'ראחמיסטרווקא',
  315: 'רבנו יעקב יוסף',
  317: 'רמ"א',
  318: 'רמת אלחנן',
  319: 'שארית יוסף',
  320: 'שיח התורה',
  323: 'תולדות אברהם יצחק',
  325: 'תורה ויראה',
  326: 'תורת זאב',
  327: 'תורת רפאל',
  328: 'תפארת לוי',
  329: 'תפרח לצעירים',
  353: 'נר הצפון',
  356: 'ישיבה גדולה',
  357: 'מכינה לישיבה',
  358: 'מצויינים',
  359: 'עמק',
  360: 'פורת יוסף',
  362: 'צעירים מנצסטר',
  363: 'אור יצחק',
  364: 'אשל אברהם',
  367: 'אהל משה (ק)',
  368: 'אור מאיר שמחה (נהריה)',
  369: 'אמרי נועם',
  370: 'ארחות משה',
  371: 'ארחות משה (מושב ברכיה)',
  372: 'באר יהודה',
  374: 'דעת שלמה תפרח',
  376: 'חיי משה',
  377: 'חיי תורה',
  378: 'אוצר התורה',
  379: 'יקירי ירושלים',
  380: 'אור תורה',
  381: 'טשעבין',
  382: 'כנסת יצחק',
  384: 'מאור אליהו',
  385: 'כתר תורה',
  386: 'סאט מונסי',
  387: 'סאטמאר מונסי',
  388: 'עטרת יצחק',
  389: 'נתיבות יעקב',
  390: 'פני שמואל',
  391: 'ויזניץ (ק)',
  392: 'קרית אתא לצעירים',
  394: 'רוזין',
  395: 'רינת התורה לצעירים',
  397: 'תורת חיים',
  398: 'תורת תמימה (ארה"ב)',
  400: 'אהל תמר',
  401: 'אוצר דעת',
  402: 'אמונת ישראל',
  404: 'ארחות משה (מושב ברכיה)',
  405: 'באר יעקב',
  406: 'בית אליהו',
  407: 'בית אריה',
  408: 'בית יוסף',
  409: 'בני שלמה',
  410: 'בצל החכמה',
  411: 'ברוקלין',
  412: 'ברית יעקב',
  414: 'הלכה למשה',
  415: 'ויזניץ',
  416: 'זכרון חיים',
  417: 'חוג חת"ס ז"מ',
  418: 'חמדת שמואל',
  419: 'כנסת התורה',
  420: 'לומזה',
  421: 'ליבו חפץ',
  423: 'מאור חזקיהו',
  426: 'נוה אשר אופקים',
  427: 'נחלת ישראל',
  429: 'נתיבות יוסף',
  430: 'סאטמאר',
  431: 'עטרת צבי',
  432: 'פונוביז מ"ע',
  433: 'קול הנער',
  434: 'שבות עמי',
  435: 'שערי הלכה (טשערנוביל)',
  436: 'שערי יוסף',
  437: 'תולדות בנימין',
  438: 'תפרח',
  440: 'תפארת יעקב (יבנה)',
  443: 'תושיה (תפרח)',
  446: 'בית הלל',
  450: 'אמת ואמונה',
  451: 'נאווימינסק',
  452: 'מתיבתא עץ חיים (באבוב)',
  454: 'קרית אתא',
  455: 'דרך עץ חיים',
  456: 'גני הדר',
  458: 'נפש חיים',
  459: 'נחלת דוד לצעירים',
  462: 'באר אברהם (סלונים)',
  463: 'קהל פרושים',
  464: 'רכסים',
  473: 'שערי תבונה',
  476: 'להוראה באבוב',
  479: 'עץ חיים (וויליך)',
  480: 'פונוביז',
  482: 'בית מדרש עליון',
  485: 'תורה תמימה',
  486: 'באר ישראל',
  488: 'באר יהושע',
  489: 'ארחות איש',
  490: 'בית שלמה',
  493: 'לצעירים רחובות',
  494: 'שערי בינה',
  495: 'גן רוה',
  498: 'בנין אב',
  499: 'מאור אליהו',
  501: 'בית יצחק',
  502: 'גאון יעקב',
  503: 'נחלת הלווים',
  504: 'אור ברוך',
  505: 'תפארת התורה',
  507: 'אביר יעקב',
  511: 'כנסת שלום',
  515: 'משכן ישראל',
  516: 'אהל משה',
  517: 'נר ישראל',
  519: 'בית דוד',
  521: 'כנסת יחזקאל',
  523: 'שערי בינה',
  527: 'כנסת יצחק (קר"ס)',
  530: 'באר יהודה',
  531: 'אור ברוך',
  533: 'רינת התורה',
  536: 'ים התורה',
  539: 'ירא שמי',
  540: 'חזו"א',
  542: 'עטרת בן ציון',
  545: 'דרכי יוסף ר"מ',
  548: 'באר התורה לצעירים',
  549: 'גדולה',
  551: 'אמרי צבי',
  553: 'דבר תורה',
  554: 'אמרי צבי',
  555: 'ר"מ',
  556: 'קול תורה',
  559: 'הרב מנדל',
  561: 'קהילות יעקב',
  564: 'אמת ואמונה (ויזניץ)',
  565: 'וויעליפאל',
  567: 'תפארת אלימלך',
  569: 'בית מדרש גבוה - לייקווד',
  570: 'ביה"מ לתורה-מונסי',
  571: 'נייטרא',
  572: 'תפארת ישראל - אלכסנדר',
  573: 'בית יוסף (גייטסהעד)',
  576: 'שער התורה',
  577: 'בית מאיר',
  578: 'אור ישראל',
  579: 'פונביז לצעירים',
  580: 'כנסת שלום',
  583: 'ארחות תורה',
  584: 'מיר רמת שלמה',
  585: 'תורה וחסד',
  586: 'שערי תבונה',
  587: 'לקח טוב',
  588: 'הרצליה',
  591: 'דבר תורה',
  593: 'שער התלמוד',
  594: 'מיר לצעירים',
  595: 'תפארת יעקב (ז"י)',
  597: 'ברכת אריה (פרשבורג)',
  599: 'טשבין',
  603: 'כנסת יצחק',
  605: 'באר התורה',
  606: 'מבקשי ה',
  607: 'דעסקין דאורייתא',
  608: 'תפארת בחורים-נתיבות',
  610: 'מקור חיים',
  612: 'אמרי שפר',
  614: 'תהילת שלמה',
  616: 'זכרון גבריאל',
  617: 'זכרון אלימלך',
  618: 'באר אברהם-סלונים',
  622: 'בני בנימין',
  623: 'לצעירים',
  625: 'דרך התורה',
  626: 'טשאבא',
  629: 'נזר מרדכי',
  630: 'בית בנימין',
  631: 'עיון סקווירא',
  632: 'אור תורה',
  633: 'דחסידי בעלזא',
  637: 'תפארת מרדכי',
  639: 'זכרון מיכאל (ז"י)',
  640: 'מכנבוקא (ב"ב)',
  641: 'אהל יוסף',
  643: 'חושן משפט',
  644: 'באר התורה',
  647: 'חו"מ',
  650: 'חיי משה',
  651: 'אורחות משה - ברכיה',
  655: 'מכנובקא (ב"ב)',
  657: 'ישמח משה',
  661: 'מעייני תורה למשה',
  662: 'אהבת תורה',
  663: 'ראשית חכמה',
  667: 'תפארת בחורים (נתיבות)',
  670: 'תורת ישראל',
  673: 'זכרון אלימלך',
  675: 'גדולה רמ"א',
  676: 'תתן אמת ליעקב',
  677: 'אבן ישראל',
  679: 'וייטרא',
  680: 'מיר ברכפלד',
  682: 'סאטמער',
  683: 'מרה"ת',
  685: 'הרב סורוצקין',
  686: 'ארחות יעקב',
  687: 'הגר"ח אהרנטריא',
  691: 'יגדיל תורה',
  694: 'באר משה',
  698: 'פונביז',
  699: 'כנסת התורה',
  701: 'פילדפיא',
  703: 'מתיבתא בית ישראל (בארא פארק)',
  704: 'קטנה דיבסנהורסק',
  705: 'ברכת יעקב',
  706: 'ראחמסטריווקא',
  707: 'אלכסנדר',
  708: 'מנחת יצחק',
  709: 'סונדרלנד',
  710: 'עדות ביהוסף',
  712: 'גדולה דטורנטו',
  714: 'עץ חיים',
  715: 'מאורות התורה',
  717: 'תורת עולם',
  720: 'תומכי תמימים - ביתר',
  722: 'צעירים',
  725: 'המעיין',
  726: 'לומדי תורה',
  729: 'טשערנאביל',
  732: 'רמת שלמה',
  733: 'היכל טוביה',
  736: 'נחלת איש',
  737: 'אור אליצור',
  738: 'חכמת שלמה - באבוב',
  739: 'בית מתתיהו',
  741: 'פרי עץ',
  742: 'לצעירים מנצסטר',
  743: 'אור תורה-בעלזא',
  744: 'הרבנים',
  745: 'אור תורה-מנצסטר',
  746: 'בעלזא לצעירים',
  747: 'חזון עובדיה',
  749: 'בית ישראל-אשדוד',
  750: 'גרודנא (ב"י)',
  751: 'משכן התלמוד',
  752: 'משכן התלמוד-אופקים',
  754: 'בית ישראל (גור)',
  755: 'אקסה לה בן',
  756: 'חכמי לובלין',
  758: 'זכרון דוד',
  759: 'חמד',
  761: 'סקווירא',
  762: 'זכרון יעקב מנחם',
  763: 'קרן התורה',
  764: 'גייטסהעד',
  765: 'וויזניץ',
  767: 'תושיה (תפרח)',
  768: 'באר אברהם סלונים',
  769: 'מחזיק ברכה פרדס חנה',
  771: 'תולדות אהרן',
  776: 'אמרי אמת (גור)',
  777: 'לומדי תורה (ב"ב)',
  779: 'אהבת משה',
  780: 'זכרון אליעזר',
  782: 'דברי מרדכי',
  786: 'חידושי הרי"ם (ת"א)',
  787: 'זכרון ירוחם',
  788: 'אוהל שמואל',
  789: 'בית ישראל (אשדוד)',
  790: 'לעלוב',
  791: 'חזון נחום יואל',
  792: 'אשדוד',
  794: 'מורשה',
  795: 'באר התלמוד-יקירי ירושלים',
  798: 'פינטו',
  799: 'נצח ישראל',
  800: 'ש"ס-ויזניץ',
  801: 'בית אברהם ויעקב',
  802: 'גדולה דפאסייק',
  803: 'תלמידי טעלז',
  812: 'שארית יוסף',
  816: 'מיר',
  818: 'חוות דעת',
  821: 'כנסת יחזקאל (אלעד)',
  823: 'אביעזרי',
  826: 'כנסת יצחק (חדרה)',
  831: 'תפארת צבי',
  832: 'מאור ישראל',
  833: 'הכותל',
  835: 'ישיבת ר"ע חסידא',
  836: 'מאור התורה',
  837: 'תורה ודעת',
  838: 'אוצר דעת',
  839: 'מלאכת שמים',
  840: 'כף החיים',
  841: 'אור ישראל',
  842: 'יוסף לקח',
  843: 'שערי מאיר',
  845: 'רב קהילה',
  849: 'חברון',
  850: 'תפארת שמואל',
  852: 'טשערנוביל',
  853: 'ר"מ',
  854: 'היכל התלמוד',
  855: 'תפארת ישראל',
  857: 'קול יהודה',
  858: 'כנסת יעקב[',
  859: 'עמלה של תורה',
  862: 'חדוות התלמוד',
  863: 'מאור התלמוד לצעירים',
  866: 'בעלזא',
  868: 'קול יעקב',
  872: 'מרכז לתורה',
  876: 'מאור התלמוד',
  879: 'ולוזין',
  880: 'קשר',
  881: 'שערי יושר',
  884: 'עץ חיים - ווילריך',
  885: 'ויזניץ',
  886: 'בית יוסף - גייטסהעד',
  889: 'שומרי החומות1',
  891: 'בעלזא-מכנובקא',
  895: 'אור יוסף',
  899: 'באר אברהם',
  900: 'דעת שלמה',
  902: 'מיר-רכס',
  903: 'שערי תורה',
  905: 'זכרון שאול',
  906: 'זכרון יהודה אהרן',
  907: 'חסדי יחיאל',
  910: 'רוממה',
  911: 'אדרת אליהו',
  912: 'תורת דב"ש',
  913: 'לימוד סוגיות-אור יודא',
  915: 'זכרון אלימלך-קרלין סטלין',
  917: 'תורת חיים-חולון',
  918: 'שירת אהרן',
  919: 'נחלת נפתלי-צפת',
  920: 'נזר אברהם',
  921: 'פרי הרים',
  922: 'מאור הנצח-ברסלב',
  923: 'עמרם חסידא',
  924: 'אמרי אמת - גור',
  925: 'כוכב מיעקב - טשעבין',
  926: 'לייקווד לצעירים',
  928: 'אמרי יוסף',
  929: 'משכנות התורה',
  930: 'דרכי דוד',
  931: 'מעין גנים',
  932: 'מכינה',
  933: 'כנסת הגדולה',
  934: 'אור יהודה',
  935: 'פורת יוסף-העיר העתיקה',
  936: 'חזון עובדיה',
  937: 'ארץ הצבי',
  938: 'עיון התלמוד',
  939: 'תורה אור',
  940: 'כרם ביבנה',
  941: 'הוראה דבאבוב',
  942: 'בית דוד - עטרת שלמה',
  944: 'נתיבות התורה',
  945: 'צעירים מנשסטער',
  946: 'אברכים דחס"ג',
  947: 'יוסף לקח - אופקים',
  950: 'עטרת שלמה - נאות הפסגה',
  951: 'ישיבת הנגב',
  952: 'ים התורה לצעירים',
  953: 'מתיבתא ראשית חכמה',
  954: 'רוזין-תפארת ישראל',
  956: 'ברכת יוסף',
  958: 'אהבת תורה',
  959: 'עיון-בעלזא',
  964: 'אוצרות התורה',
  965: 'עמוד האש',
  966: 'הר המור',
  967: 'הר המור',
  968: 'יורו משפטיך',
  969: 'בית אלחנן דב',
  971: 'באר אברהם',
  972: 'גדולה דמכסיקו - עמק',
  973: 'מאור יצחק',
  974: 'אוסטרווצע',
  977: 'רה"ב דפשעווארסק',
  979: 'מתיבתא דלייקווד',
  982: 'בית מדרש גבוה',
  983: 'אור התלמוד',
  984: 'משכן דוד עטרת שלמה',
  985: 'בית התלמוד-גייטסהעד',
  988: 'תורת עולם (בית שמש)',
  989: 'תורה וחיים',
  991: 'עליות אליהו',
  992: 'שערי תבונה-אלעד',
  993: 'חניכי חברון - ב"ב',
  994: 'תורה ודעת',
  995: 'תורת משה',
  996: 'תורת שמחה',
  997: 'נר ישראל - גור',
  998: 'פני יהושע',
  999: 'שערי הלכה',
  1000: 'אשרי האיש',
  1002: 'שערי תלמוד',
  1003: 'בית מדרש גבוה להוראה-רמת השרון',
  1004: 'אהבת עולם',
  1006: 'בית אהרן וישראל - קרלין',
  1007: 'באבוב',
  1008: 'למען אחי',
  1009: 'ממלכת התורה',
  1011: 'מאור עיניים-ראחסטריווקא',
  1013: 'מחנובקא',
  1014: 'מכנובקא',
  1015: 'נחלת יוסף',
  1016: 'תפארת יהודה',
  1018: 'באר אברהם (סלאנים)',
  1019: 'באר יצחק (תפרח)',
  1020: 'משנת הנחל',
  1022: 'הרב קנינסקי',
  1023: 'ברסלב',
  1025: 'עטרת שלמה',
  1026: 'בני יהודה',
  1027: 'צאנז-קלויזינבורג',
  1028: 'גדולה דנייטרא',
  1029: 'מתיבתא דבאלטימאר',
  1030: 'יחוה דעת',
  1031: 'פתחי שערים',
  1032: 'קרלין סטאלין',
  1033: 'ים התלמוד',
  1035: 'ויזניץ מודיעין עילית',
  1036: 'חסד לאברהם',
  1037: 'מקדש שאול',
  1039: 'שארית יוסף',
  1040: 'קהילות יעקב',
  1041: 'אבי עזרי',
  1042: 'אור דוד',
  1043: 'הרב רפופרט',
  1044: 'ישועות משה',
  1045: 'עטרת משה',
  1046: 'כנסת חזקיהו',
  1047: 'נחלת שמואל',
  1048: 'דעת תבונות',
  1050: 'שירת הצאן',
  1053: 'תפארת שמעון',
  1055: 'עטרת אברהם',
  1056: 'שערי יושר',
  1057: 'זכרון יעקב',
  1058: 'חמד (רשל"צ)',
  1059: 'מי התורה בשרון',
  1060: 'עטרת אבות',
  1062: 'מרומי שדה',
  1063: 'אורות חיים ומשה',
  1064: 'אוצר הדעת',
  1069: 'בית מדרש דבאלטימאר',
  1070: 'קטנה מינט קיסקא',
  1073: 'שובה ישראל',
  1074: 'עטרת יוסף',
  1075: 'תפארת הגר"א',
  1076: 'אהבת ישראל',
  1078: 'היכל דוד',
  1079: 'עמלי תורה',
  1081: 'אורות אברהם',
  1084: 'קול אריה',
  1085: 'עטרת שלמה-אופקים',
  1086: 'באר מים חיים',
  1087: 'הגרמ"ד מבריסק',
  1088: 'משכן משה',
  1089: 'זרועה',
  1090: 'מעלות קדושים',
  1093: 'הדרת תורה',
  1094: 'דרכי יושר  - באבוב',
  1095: 'שערי טובה',
  1096: 'לשם שמים',
  1097: 'לבוש מלכות',
  1098: 'נתיבות שלמה',
  1099: 'חתן סופר',
  1100: 'חיים ברלין',
  1101: 'מיר (פלעטבוש)',
  1102: 'יד חיים מרדכי',
  1104: 'מתיבתא עטרת יוסף',
  1105: 'זכרון שאול',
  1106: 'בית תלמוד להוראה',
  1107: 'שלום היהדות',
  1108: 'חסדי יוסף',
  1109: 'בית אשר קרלין סטולין',
  1111: 'תורת אליהו',
  1112: 'ארחות חיים',
  1113: 'שמחת התורה',
  1114: 'מעיין התלמוד',
  1115: 'חמדת צבי',
  1116: 'בית ישראל גור',
  1117: 'קרלין ביתר',
  1118: 'נדבורנה',
  1119: 'הלכה בית וגן',
  1120: 'נחלי דעת',
  1121: 'היכל התורה',
  1125: 'קהילת משך חכמה',
  1126: 'אור אליהו',
  1127: 'נחלת משה',
  1128: 'צדקת ישראל',
  1129: 'נחלת הלויים',
  1130: 'בית יצחק',
  1131: 'נתיבות עולם',
  1134: 'נחלת יצחק',
  1135: 'נחל יצחק',
  1136: 'שערי דוד',
  1137: 'תפארת שיח מרדכי',
  1138: 'מחזיקי תורה',
  1139: 'תפארת שמשון',
  1140: 'משאת משה',
  1141: 'דרך חיים',
  1142: 'בטחה',
  1143: 'פורת יוסף-גאולה',
  1144: 'ברדיטצוב',
  1145: 'רבינו חיים עוזר',
  1147: 'בית יהודא',
  1149: 'לבו חפץ',
  1150: 'עטרת התורה',
  1151: 'באר יצחק',
  1152: 'זכרון קלם',
  1153: 'תפארת בחורים',
  1154: 'מאור ישראל',
  1155: 'יששכר באהליך',
  1156: 'בוסטון',
  1157: 'כתב סופר',
  1158: 'צאנז - ביתר',
  1159: 'היכל התורה',
  1163: 'דמשק אליעזר (ויזניץ)',
  1165: 'ראחמסטריווקע',
  1166: 'משכן התורה',
  1168: 'זכרון יעקב',
  1169: 'ברכת שמואל',
  1170: 'ישועה ליהודה',
  1171: 'עטרת ישראל לצעירים',
  1172: 'לשכנו תדרשו',
  1174: 'ריב"ש ורשב"ץ',
  1175: 'הדרת התורה',
  1176: 'אור התלמוד',
  1177: 'לצעירים ציריך',
  1178: 'בית וגן',
  1179: 'נחלת נפתלי',
  1180: 'זכרו תורת משה',
  1181: 'נר אליהן',
  1182: 'ת"ת ויזניץ',
  1183: 'באר שמחה',
  1184: 'זכרון משה',
  1185: 'בית מקרא',
  1186: 'נועם אלימלך',
  1187: 'עטרת שמעון',
  1188: 'לומדי תורה',
  1189: 'זכרון מרדכי',
  1190: 'קצות',
  1193: 'תורה והוראה (ת"א)',
  1194: 'בר שאול',
  1195: 'קול אריה',
  1196: 'קהל חסידים',
  1198: 'דעת תורה',
  1199: 'לובלין',
  1200: 'ברכת יצחק',
  1201: 'דעת שלמה (תפרח)',
  1202: 'גבעת שאול',
  1206: 'הלכה.',
  1207: 'באר העלם',
  1208: 'שירה חדשה',
  1209: 'עטרת מאיר',
  1210: 'הגבעה',
  1211: 'שערי חכמה',
  1215: 'שערי דעת',
  1217: 'משכן יצחק',
  1219: 'בית אברהם סלונים',
  1220: 'עושה פרי',
  1221: 'דעת משה',
  1224: 'תוספות יום טוב',
  1225: 'קדושת אהרן',
  1226: 'בית יוסף נובהרדוק',
  1227: 'עמודי שלמה',
  1228: 'זכרון צבי',
  1230: 'בית הלוי',
  1231: 'שכט הלוי',
  1232: 'דאראג',
  1235: 'נועם אלימלך',
  1236: 'בית מדרש לתלמוד',
  1237: 'לימנוב',
  1238: 'תורת אמת קאמינעץ',
  1239: 'יגדיל תורה',
  1240: 'מעוז חיים',
  1241: 'דושניסקיא',
  1242: 'זכרון מאיר',
  1243: 'תפארת חיים',
  1244: 'ראש ישיבה',
  1245: 'יגל יעקב',
  1246: 'היכל אברהם',
  1247: 'בית בנימין-סטמפרד',
  1248: 'תפארת יעקב-ספינקא',
  1249: 'שערי תורה-חסידית',
  1250: 'באר המלך (ב"ש)',
  1251: 'הדרת דוד',
  1252: 'שפע חיים - צאנז',
  1253: 'שאגת אריה',
  1254: 'עטרת שלמה-משכן דוד',
  1255: 'מאיר עיני ישראל',
  1256: 'באר התלמוד',
  1257: 'שיח יצחק',
  1258: 'ארזי הבירה',
  1259: 'אור אלחנן',
  1261: 'טל תורה',
  1262: 'צעירים רחובות',
  1263: 'חפץ חיים',
  1264: 'קול יצחק',
  1265: 'שוטנשטין',
  1266: 'דף היומי בעיון',
  1267: 'נחלת יוסף',
  1268: 'אוצרות יצחק',
  1269: 'אש התלמוד',
  1270: 'ברכת מרדכי (ביתר)',
  1271: 'ברכת אפרים',
  1272: 'מיר-ביתר',
  1273: 'באיאן',
  1274: 'מרכז התורה',
  1276: 'מדרש חיים',
  1277: 'מעוז חיים',
  1278: 'אוהלי שמואל',
  1279: 'אמרי דעת',
  1280: 'קול רינה',
  1281: 'שערי יום טוב',
  1282: 'וילקומיר',
  1283: 'שערי תשובה וחיים',
  1284: 'תפארת יששכר',
  1286: 'שערי ניסים',
  1287: 'אור הנר',
  1288: 'נתיבי עזרא',
  1289: 'חזון נחום',
  1290: 'דברי שיר',
  1291: 'תפארת שרגא',
  1292: 'הגר"א',
  1296: 'ברכת יוסף',
  1297: 'לב אריה',
  1298: 'באר יוסף',
  1299: 'יסוד העבודה',
  1301: 'רב פעלים',
  1304: 'יד זיכל',
  1305: 'משקלב',
  1306: 'אהל שרה',
  1308: 'רמת השרון',
  1310: 'תפארת חיים',
  1312: 'מן התורה',
  1313: 'די פריהולד',
  1314: 'קהילות יעקב-חזו"י',
  1315: 'זכרון יוסף',
  1316: 'ארחות התורה',
  1317: 'דרך חיים',
  1318: 'כנסת יצחק לצעירים',
  1319: 'אוהל נפתלי',
  1320: 'משכן חיים',
  1321: 'ביתר',
  1323: 'מהרי"ט סאטמאר',
  1324: 'אמרי דעת',
  1325: 'נחלת בנימין (מודיעין עילית)',
  1326: 'עכו',
  1327: 'קניני התורה',
  1328: 'תפארת יעקוב',
  1329: 'דרכי השלימות',
  1330: 'שומרי החומות',
  1331: 'כתר דוד (העיר העתיקה)',
  1333: 'אברכים נבחרים',
  1334: 'נחלת יעקב',
  1336: 'הגרמ"ד מבריסק',
  1337: 'פרדס',
  1338: 'בית לימוד תורה',
  1339: 'ליקווד לצעירים',
  1340: 'שובי נפשי',
  1341: 'ר"מ באר יעקב',
  1342: 'אור החיים',
  1343: 'תורת חיים מ"ע',
  1344: 'תפארת שרגא',
  1345: 'תפארת שלמה (רחובות|)',
  1347: 'לפני תמיד',
  1349: 'תורת אבות',
  1350: 'לאסוקי שמעתתא',
  1351: 'זוהר התורה',
  1352: 'כוונת הלב',
  1353: 'מגדלות תפוחים',
  1354: 'שערי התלמוד',
  1355: 'משכן מאיר',
  1356: 'ירושלמי זרעים',
  1357: 'מעשי חייא',
  1358: 'אוצר תורת יצחק',
  1359: 'למען דעת',
  1360: 'ר"מ ישיבת אור נהריה',
  1361: 'חומות ירושלים',
  1362: 'אמרי מרדכי',
  1363: 'לוית חן',
  1364: 'בני ציון - באבוב',
  1365: 'וויליק',
  1366: 'תולדות ישורון',
  1367: 'שיויתי',
  1368: 'הרב דרבקין',
  1369: 'קרית ספר',
  1370: 'בית הלוי',
  1371: 'חפץ חיים',
}

export const getcodeMosad = (value) => {
  for (const [key, val] of Object.entries(LIST_OF_MOSAD)) {
    if (val === value) {
      return key;
    }
  }
  return null;
}