import React from "react";

const Detail = ({ text, label }) => (
    <div>
        <div style={{ display: 'inline-flex' }}>
            <div>{text}</div>
            <div style={{ fontWeight: 'bold', whiteSpace: 'pre' }}>{label}</div>
        </div>
    </div>
)

export default Detail;