import { Routes, Route } from 'react-router-dom';
import Login from "./login/login";
import FillFile from "./Fill_Files/upload/fill_file";
import DrawFile from "./Fill_Files/draw_file/draw_file";
import Done from "./components/done";
import Notfound from "./components/not_found";

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/fill" element={<FillFile />} />
                <Route path="/uploadedFile" element={<DrawFile />} />
                <Route path="/success" element={<Done />} />
                <Route component={Notfound} />
            </Routes>
        </>
    );
};

export default App;