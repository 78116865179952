import React from "react";
import { exm_file } from "../../texts/fill";
import { handleExport } from "../../utils/fill_file_utils";

const DownloadExample = () => {
    return (
        <button onClick={handleExport} className="btn btn-info m-3">
            {exm_file}
        </button>
    )
};

export default DownloadExample;