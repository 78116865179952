import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { update_sum } from "../../texts/fill";
import { updateSomePropertyForAll } from "../../utils/draw_file_utils";
import { sum } from "../../common/statics";

const Function = () => {
    const dispatch = useDispatch();
    const [value, setVaule] = useState();
    const file = useSelector(state => state.file);
    const user = useSelector(state => state.user);

    return (
        <div style={{ display: 'grid', marginRight: '20px' }}>
            <button className="btn btn-outline-dark" style={{ marginBottom: '10px' }}
                onClick={() => updateSomePropertyForAll(dispatch, value, sum, file, user)}>{update_sum}</button>
            <input className="form-control" value={value} onChange={e => setVaule(Number(e.target.value))}
                type={"number"} />
        </div>
    )
};

export default Function;