const initialState = null;
export const SAVE_ERROR = 'SAVE_ERROR';

export const saveError = (error) => {
    return {
        type: SAVE_ERROR,
        payload: error,
    };
};

export const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ERROR:
            return action.payload;
        default:
            return state;
    }
};
