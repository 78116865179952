import React, { useState } from "react";
import { enter, enterDetails, mailText, not_approved, passwordText, user_not_exists, welcome } from "../texts/login";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ErrorAlert from "../common/errorAlert";
import SecondaryAlert from "../common/secondaryAlert";
import { saveUser } from "../context/userReducer";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [response, setResponse] = useState();
  const [load, setLoad] = useState(false);


  const checkUserExists = (e) => {
    e.preventDefault();

    if (mail && password) {
      setLoad(true)
      fetch("https://localhost:44382/api/partnership/IsUserExists", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mail: mail,
          password: password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoad(false)
          setResponse(data.status)
          if (data.status === 1) {
            dispatch(saveUser(data.obj));
            navigate("/fill");
          }
        })
        .catch(() => { alert("נתקלנו בבעיה"); setLoad(false) })
    }
  }

  return (
    <div style={{ display: 'inline-block', width: '43%', paddingTop: '50px' }}>
      <h1 className="display-6 p-3">{welcome}</h1>
      <h5 className="display-7 m-5">{enterDetails}</h5>
      <br />
      <br />
      <form onSubmit={checkUserExists}>
        <div className="form-group">
          <label>{mailText}</label>
          <input value={mail} onChange={e => setMail(e.target.value)} type="email" className="form-control"
          />
        </div>
        <br />
        <div className="form-group">
          <label>{passwordText}</label>
          <input value={password} onChange={e => setPassword(e.target.value)} type="password"
            className="form-control" />
          <br />
        </div>
        {!load && <button className="btn btn-primary"
        >{enter}</button>}
        {load && <button className="btn btn-primary" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        </button>
        }
        {response && response === 2 && <ErrorAlert msg={not_approved} />}
        {response && response === 3 && <SecondaryAlert msg={user_not_exists} />}
      </form>
    </div>
  );
};

export default Login;
