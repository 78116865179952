import { read, utils, writeFile } from "xlsx";
import { CSV_HEADERS } from "../common/statics";
import { isRowsExists } from "./validation_utils";
import { saveFile } from "../context/fileReducer";

export const handleExport = () => {
    const headings = [CSV_HEADERS]
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, [], { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Report');
    writeFile(wb, 'קובץ שותפויות.xlsx');
}


export const handleImport = ($event, dispatch) => {
    let newValue, result;
    if ($event) {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    newValue = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    result = handleSubmit(newValue, dispatch);
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }
    return result
}

const handleSubmit = (newValue, dispatch) => {
    if (isRowsExists(newValue)) {
        dispatch(saveFile(newValue));
        return true
    }
    return false
};