export const details = "פרטי שותף ושותפות";
export const currentMilga = " :מלגה נוכחית";
export const approvalAmount = " :סכום שאושר";
export const enteredAmount = " :סכום שהוכנס";
export const amountLimit = " :הגבלת סכום לאברך";
export const foodChain = " :רשת מזון";
export const upload = "אנא העלה קובץ";
export const upload_button = "העלה";
export const exm_file = "לחצו כאן - הורידו את הקובץ והעלו אותו מלא";
export const empty_file = "הקובץ ריק";
export const high_sum = "הסכום הכולל גבוה מהסכום שנקבע";
export const update_sum = "עדכן סכום אחיד לכולם";
export const update_food_chain = "עדכן רשת מזון לכולם";
export const update_colel = "עדכן כולל לכולם";
export const update_ishuv = "עדכן יישוב לכולם";
export const reupload = "להעלאת קובץ מחדש";
export const send_file = "לשליחת הקובץ";
export const tooltip = "שליחת קובץ אפשרית רק כאשר כל השדות תקינים";
export const currency = " שח";
