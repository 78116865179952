import React from "react";
import { send_file, tooltip } from "../../texts/fill";
import { useState } from "react";
import { buildRequest, handleErrors } from "../../utils/handle_file";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SendFileButton = () => {
  const sumAllError = useSelector(state => state.sumAllError);
  const error = useSelector(state => state.error);
  const file = useSelector(state => state.file);
  const [load, setLoad] = useState(false);
  const [sent, setsent] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const sendFile = () => {
    setLoad(true)
    setsent(true)
    fetch("https://localhost:44382/api/partnership/saveFile/" + user.representativeCode, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        buildRequest(file, dispatch)
      ),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoad(false)
        if (Object.keys(data).length === 0) {
          navigate("/success");
        }
        else {
            handleErrors(data, file, dispatch)
        }
      })
      .catch(() => {
        setLoad(false)
        setsent(false)
      })
  }

  return (
    <>
      {!load && (
        <span tabindex="0" data-toggle="tooltip" title={tooltip}>
          <button
            type="button"
            className="btn btn-outline-primary btn-lg"
            onClick={sendFile}
            disabled={sent || error !== null || sumAllError != null}
          >
            {send_file}
          </button>
        </span>
      )}
      {load && (
        <button className="btn btn-primary" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <div>
            אנו בודקים את תקינות הקובץ
            <br />
            ומזינים במערכת את הנתונים
          </div>
        </button>
      )}
    </>
  );
};

export default SendFileButton;