import React from "react";


const NotFound = () => {
  return (
    <div style={{ display: 'inline-block', width: '43%', paddingTop: '50px' }}>
      <h1 className="display-1 p-5">עמוד לא נמצא</h1>
    </div>
  );
};

export default NotFound;
