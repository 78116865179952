export const SAVE_USER = 'SAVE_USER';

export const saveUser = (user) => {
    return {
        type: SAVE_USER,
        payload: user,
    };
};

const initialState = null;

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER:
            return action.payload;
        default:
            return state;
    }
};
