const initialState = null;
export const SAVE_SUM_ALL_ERROR = 'SAVE_SUM_ALL_ERROR';

export const saveSumAllError = (error) => {
    return {
        type: SAVE_SUM_ALL_ERROR,
        payload: error,
    };
};

export const sumAllErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_SUM_ALL_ERROR:
            return action.payload;
        default:
            return state;
    }
};
