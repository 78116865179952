export const last_name = 'שם משפחה';
export const first_name = 'שם פרטי';
export const street = 'רחוב';
export const num = 'מספר';
export const ishuv = 'ישוב';
export const phone = 'טלפון';
export const mobilePhone = 'נייד';
export const id = 'מספר ת.ז. (ללא 0 בהתחלה)';
export const colel = 'שם כולל';
export const children_num = 'מספר ילדים';
export const bankNum = 'מספר בנק';
export const bankBranchNum = 'מספר סניף';
export const bankAccount = 'מספר חשבון';
export const exists_card = 'מספר כרטיס אידיש כארד באם קיים';
export const food_chain = 'רשת מזון';
export const sum = 'סכום (לאחר אישור מול המערכת)';
export const comments = 'הערות';


export const CSV_HEADERS = [
    last_name,
    first_name,
    street,
    num,
    ishuv,
    phone,
    mobilePhone,
    id,
    colel,
    children_num,
    bankNum,
    bankBranchNum,
    bankAccount,
    exists_card,
    sum,
    food_chain,
    comments
];

export const REQUIRED_FIELDS = (user) => [
    last_name,
    first_name,
    street,
    num,
    ishuv,
    mobilePhone,
    id,
    colel,
    sum,
    ...(user.foodChain == null ? [food_chain] : [])
];


export const INT_FIELDS = [
    num,
    id,
    children_num,
    bankNum,
    bankBranchNum,
    bankAccount,
    exists_card,
    sum,
];

export const TABLE_HEADERS = [
    last_name,
    first_name,
    street,
    num,
    ishuv,
    phone,
    mobilePhone,
    id,
    colel,
    children_num,
    bankNum,
    bankBranchNum,
    bankAccount,
    exists_card,
    sum,
    food_chain,
    comments
];