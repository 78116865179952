import { CSV_HEADERS } from "../common/statics";
import { validateRow } from "./validation_utils";
import React from "react";
import { updatePropertyForAll } from "../context/fileReducer";
import { saveError } from "../context/errorReducer";
import { saveSumAllError } from "../context/sumAllErrorReducer";
import { isSumAllMatching } from "../utils/validation_utils";
import { comments } from "../common/statics";

export const updateSomePropertyForAll = (dispatch, newValue, field, file, user) => {
    dispatch(updatePropertyForAll(field, newValue));
    if (!isSumAllMatching(file, user, newValue)) {
        dispatch(saveSumAllError("sum_all"))
    }
    else {
        dispatch(saveSumAllError(null))
    }
};

export const getRow = (originalRow, user, dispatch) => {
    const row = createRow(originalRow)
    let style = "";
    const errors = validateRow(row, user);
    if (errors.length > 0 || row[comments] !== "") {
        style = "table-danger"
        row[comments] = row[comments] + writeErrors(errors)
        dispatch(saveError(comments))
    }

    return Object.values(row).map((column) => {
        const finalStyle = getStyle(column, style);
        return <td className={finalStyle}>{column}</td>
    })
}

const getStyle = (column, style) => {
    let emptyStyle = getEmptyStyle(column);
    return emptyStyle !== "" ? emptyStyle : style
}

const getEmptyStyle = column => {
    let emptyStyle = "";
    if (column === "") {
        emptyStyle = "table-active"
    }
    return emptyStyle;
}

const createRow = (row) => {
    let newRow = {}
    const keys = Object.keys(row)
    for (let i = 0; i < CSV_HEADERS.length; i++) {
        const headersKey = CSV_HEADERS[i]
        if (keys.includes(headersKey)) {
            newRow[headersKey] = row[headersKey]
        } else newRow[headersKey] = ""
    }
    return newRow
}

const writeErrors = (errors) => {
    return errors.map((e, index) => index < errors.length - 1 ? e + ",  " : e)
}