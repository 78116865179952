import React from "react";
import { useSelector } from "react-redux";
import UserDetails from "./user_details";
import FileHandler from "./file_handler";

const FillFile = () => {
  const user = useSelector((state) => state.user);
  return (
    <div className="card" style={{ marginTop: "5%" }}>
      <UserDetails user={user} />
      <hr className="my-4" />
      <br />
      <FileHandler user={user} />
      <br />
      <br />
    </div>
  );
};

export default FillFile;