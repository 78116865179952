import React from "react";


const Done = () => {
  return (
    <div style={{ display: 'inline-block', width: '43%', paddingTop: '50px' }}>
      <h1 className="display-6 p-5">הקובץ נקלט בהצלחה</h1>
    </div>
  );
};

export default Done;
