import React from "react";
import { amountLimit, approvalAmount, currentMilga, enteredAmount, foodChain, details } from "../../texts/fill";
import Detail from "./detail";

const UserDetails = ({ user }) => {
    return (
        <div className="card-body" style={{ textAlign: 'right' }}>
            <h3 className="display-5 font-weight-bold">{details}</h3>
            <h4 className="display-6 font-weight-bold">{user.name}</h4>
            <h5 className="display-7">{user.area}</h5>
            <br />
            <Detail label={currentMilga} text={user.currentMilga} />
            <Detail label={approvalAmount} text={user.approvalAmount} />
            <Detail label={enteredAmount} text={user.enteredAmount} />
            <Detail label={amountLimit} text={user.amountLimitForEach} />
            {user.foodChain !== null && <Detail label={foodChain} text={user.foodChain} />}
        </div>
    )
};


export default UserDetails;