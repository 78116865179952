const LIST_OF_FOOD_CHAIN = {
  1: 'אהבת חסד',
  2: 'בר כל ונתיב החסד',
  3: 'זול ובגדול',
  4: 'כרטיס אידיש כארד',
  5: 'מעיין 2000',
  6: 'שפע ברכת השם',
  7: 'משנת יוסף',
  8: 'שפע ברכת השם ישן',
  9: 'חסד לאלפים',
  10: 'בר כל תלושים',
  11: 'יש תלושים',
  12: 'הפקדה',
  14: 'שערי רווחה',
  15: 'נת',
  16: 'למשנ',
  17: '1',

}

export const getFoodChainCode = (value) => {
  for (const [key, val] of Object.entries(LIST_OF_FOOD_CHAIN)) {
    if (val === value) {
      return key;
    }
  }
  return null;
}
