const LIST_OF_ISHUV = {
  1: 'ירושלים',
  2: 'בני ברק',
  3: 'נתניה',
  4: 'חדרה',
  5: 'בת ים',
  6: 'אלעד',
  7: 'חיפה',
  8: 'פרדס חנה',
  9: 'עמנואל',
  10: 'אלומה ד.נ. שדה גת',
  11: 'אופקים',
  12: 'אור יהודה',
  14: 'אנגליה',
  15: 'ארה"ב',
  16: 'אשדוד',
  17: 'אשקלון',
  18: 'בית שמש',
  19: 'באר יעקב',
  21: 'ביתר',
  22: 'ביתר עלית',
  23: 'גבעת זאב',
  24: 'ד.נ. הנגב',
  25: 'ד.נ. חוף עזה',
  26: 'ד.נ. מודיעין',
  27: 'ד.נ. נחל שורק',
  28: 'זכרון יעקב',
  30: 'חולון',
  31: 'יבנה',
  32: 'יהוד',
  33: 'ירוחם',
  34: 'כרמיאל',
  35: 'מודיעין עילית',
  36: 'מעלות',
  37: 'נאות הפסגה',
  38: 'נתיבות',
  42: 'פתח תקוה',
  43: 'צפת',
  44: 'קרית ספר',
  45: 'קרית באבוב',
  46: 'קרית גת',
  47: 'קרית מלאכי',
  48: 'רחובות',
  49: 'רכסים',
  50: 'רמת בית שמש',
  51: 'רמת גן',
  52: 'תל אביב',
  53: 'תל ציון',
  54: 'תפרח',
  55: 'טירת הכרמל',
  56: 'ברכפלד',
  57: 'ברזיל',
  58: 'טבריה',
  59: 'מושב זבדיאל',
  60: 'נהריה',
  61: 'גן יבנה',
  62: 'טלזסטון',
  63: 'גני תקוה',
  64: 'בלגיה',
  65: 'ארגנטינה',
  66: 'מעלה אדומים',
  67: 'מכסיקו',
  68: 'חמד',
  69: 'כפר חסידים',
  70: 'מגדל העמק',
  71: 'מושב ברכיה',
  72: 'לייקווד',
  73: 'ברוקלין',
  74: 'מונסי',
  75: 'סקווירא',
  76: 'אבן שמואל',
  77: 'אזור',
  78: 'בני עיש',
  79: 'גבעת שמואל',
  80: 'זבדיאל',
  81: 'חו"ל',
  82: 'לוד',
  83: 'לונדון',
  84: 'מושב פורת',
  85: 'מעל',
  86: 'ספרינג וואליא',
  87: 'ער',
  88: 'צרפת',
  89: 'קנדה',
  90: 'קרית אתא',
  91: 'קרית ביאליק',
  92: 'קרית מוצקין',
  93: 'ראשון לציון',
  94: 'רמלה',
  95: 'שדרות',
  98: 'אנטווערפען',
  99: 'מתתיהו',
  100: 'טורנטו',
  101: 'כפר מימון',
  103: 'ד.נ. יפתח',
  104: 'באר שבע',
  105: 'מנצסטר',
  106: 'מבשרת ציון',
  107: 'חשמונאים',
  108: 'מאנסי',
  109: 'ניו יורק',
  110: 'בארא פארק',
  111: 'רמת השרון',
  112: 'קרית ים',
  113: 'קרית עקרון',
  114: 'עכו',
  115: 'עמקא',
  116: 'בית חלקיה',
  117: 'פילידפיא',
  118: 'ראש העין',
  119: 'בית חורון',
  120: 'חצור הגלילית',
  121: 'כפר סבא',
  122: 'רעננה',
  123: 'חזון יחזקאל',
  124: 'גייטסהעד',
  125: 'כליבלאנד',
  127: 'תת',
  129: 'נייטרא',
  130: 'הרצליה',
  131: 'שעלבים',
  132: 'ערד',
  133: 'מונטריאל',
  134: 'לא ידוע',
  135: 'קרית ארבע',
  136: 'ספרינג וואלי (ארה"ב)',
  137: 'באליטמאר',
  138: 'רמת השרון-מורשה',
  139: 'כפר חסידים - רכסים',
  140: 'עקס לע בין',
  141: 'בית שאן',
  142: 'דימונה',
  143: 'יד אליהו תל אביב',
  144: 'הנגב',
  146: 'מינט קיסקא',
  148: 'מודיעין',
  149: 'עפולה',
  150: 'כפר הרואה',
  151: 'קוממיות',
  152: 'שבי שומרון',
  153: 'נוף איילון',
  154: 'אילת',
  155: 'דרום אפריקה',
  156: 'שדה גת',
  158: 'יד רמב"ם',
  159: 'שוויץ',
  160: 'פאוזקורג',
  161: 'וויליאמסבורג',
  162: 'ד.נ. אבטח',
  163: 'תלמים',
  166: 'קרית שמונה',
  167: 'גדרה',
  168: 'שטרסבורג - צרפת',
  169: 'סטמפרד היל',
  170: 'קווינס',
  171: 'אור עקיבא',
  172: 'מאנרוי',
  173: 'יעבץ',
  174: 'בואנס איירס',
  175: 'וינה',
  176: 'מאנטריאל',
  177: 'פאסייק',
  178: 'פלאנג',
  179: 'פלאשינג',
  180: 'יאניאון סיטי',
  181: 'יסודות',
  182: 'אור התלמוד',
  184: 'מאור התורה',
  185: 'כפר עקווא',
  186: 'ד.נ. שדה גת',
  187: 'מאנרא',
  188: 'מרסיי',
  189: 'קוממיות ד.נ. שדה גת',
  190: 'ברכיה',
  191: 'מבוא חורון',
  192: 'ניר משה',
  193: 'בני ראם',
  194: 'מושב נועם',
  195: 'אור הגנוז',
  196: 'כפר ורדים',
  197: 'אתרוג',
  198: 'כוכב יעקב',
  199: 'יד בנימין',
  200: 'פסאייק',
  201: 'מגדל',
  202: 'ד.נ. חוף אשקלון',
  203: 'אופר',
  204: 'נחליאל',
  205: 'גבע בנימין',
  206: 'כרם ביבנה',
  208: 'פריז - צרפת',
  209: 'ת.ד. 778 אבן יהודה',
  210: 'רמות',
  211: 'קרני שומרון',
  212: 'בעזל - שוויץ',
  213: 'חברון',
  214: 'אחוזת אתרוג',
  215: 'ציריך',
  216: 'ד.נ. הרי יהודה',
  217: 'חרשה',
  218: 'נשר',
  219: 'תירוש',
  220: 'קרטי - צרפת',
  221: 'בואנס איירס - ארגנטינה',
  222: 'בית הגדי',
  223: 'סידערהרסט',
  224: 'שדה יצחק',
  225: 'בנימינה',
  226: 'ת.ד. 13 רמלה',
  227: 'ת.ד. 16 רמלה',
  229: 'ענגלנד - לונדון',
  230: 'נחום',
  231: 'כנען - צפת',
  232: 'שלומי',
  235: 'בית זית',
  236: 'מושב יערה',
  237: 'שיקאגו',
  238: 'קרית יערים',
  239: 'מגדים',
  240: 'פתר',
  241: 'תפרח ד.נ. הנגב',
  242: 'ד.נ. מרום הגליל',
  243: 'הר יונה',
  244: 'קרית אונו',
  245: 'אלפי מנשה',
  246: 'אוסטרליה',
  247: 'מושב זרועה',
  248: 'נוה אילן',
  249: 'גבעתיים',
  250: 'אפרת',
  251: 'הרי יהודה',
  252: 'מושב דלתון',
  253: 'יערה',
  254: 'בת שמש',
  255: 'פסגת זאב',
  256: 'יפו',
  257: 'נצרת עלית',
  258: 'ת.ד 16 רמלה',
  259: 'פרדס כץ',
  260: 'לאסאנדזלאס',
  261: 'בן עמי',
  262: 'אשתאול',
  263: 'בני דקלים',
  264: 'קרית שמואל - חיפה',
  265: 'מושב אחיעזר',
  266: 'גוש עציון',
  267: 'כפר חבד',
  268: 'יוקנעם',
  273: 'רכס4218',
  274: 'קרית טבעון',
  275: 'עין יעקב',
  276: 'בית מש',
  277: 'לנוד',
  278: 'מעלה עמוס',
  279: 'פסגות',
  280: 'מירון',
  281: 'מושב נחום',
  282: 'נוף הגליל',
  283: 'אחיסמך',
  284: 'עפרה',
  285: 'חריש',
  286: 'יבנאל',
  287: 'בית שמ-',
  288: 'מושב יסודות',
  289: 'ביתר עילית',
  290: 'נס ציונה',
  291: 'תל אביב יפו}'
}

export const getCodeIshuv = (value) => {
  for (const [key, val] of Object.entries(LIST_OF_ISHUV)) {
    if (val === value) {
      return key;
    }
  }
  return null;
}
